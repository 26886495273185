import { Injectable } from '@angular/core';
import {jwtDecode} from  'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class StorageUtilsService {

    constructor() { }
    // setAccessToken(token: any) {
    //     localStorage.setItem('accessToken', token);
    // }

    // getAccessToken(): string {
    //     return localStorage.getItem('accessToken') ?? '';
    // }

    // /** get decode access token */
    // getDecodedAccessToken(): any {
    //     try {
    //         return jwtDecode(localStorage.getItem('accessToken'));
    //     } catch (error) {
    //         return null;
    //     }
    // }

    // removeAccessToken() {
    //     localStorage.removeItem('accessToken');
    // }

    // setUser(user: any): void {
    //     localStorage.setItem('user', JSON.stringify(user));
    // }

    // getUser(): any {
    //     const itemString = localStorage.getItem('user');
    //     return itemString ? JSON.parse(itemString) : null;
    // }

    // removeUser(): void {
    //     localStorage.removeItem('user');
    // }

}
